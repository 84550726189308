import React from 'react';
import { Link } from 'gatsby';
import "./construction.css"

export default function Construction() {
    return (
       <React.Fragment>
           <h1>This section is under construction</h1>
            <div className="image-container">
                <img className="construction-image" src="https://media.giphy.com/media/zkMri4yiJ3Mdy/source.gif" alt="Pikachu running like nyan cat"/>
                
                <Link to="/"><h3>Return Home</h3></Link>
            </div>
       </React.Fragment>
    )
}
