import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Footer from "../components/footer"
import Construction from "../components/construction"

const Illustrations = () => (
  <Layout>
      <Construction></Construction>
    <Footer/>
  </Layout>
)

export default Illustrations
